export function getSortedList(){
    return averageAges.sort(comp);
}

function comp(left, right) {
    if(left.name === right.name) return 0;
    else if (left.name > right.name) return 1;
    return -1;
}

export const averageAges = [
    { name: 'Hong Kong', averageMen: 82.9, averageWomen: 88.0 },
    { name: 'Iceland', averageMen: 81.7, averageWomen: 84.5 },
    { name: 'Japan', averageMen: 81.6, averageWomen: 87.7 },
    { name: 'Norway', averageMen: 81.6, averageWomen: 84.9 },
    { name: 'Singapore', averageMen: 81.5, averageWomen: 86.1 },
    { name: 'Macao', averageMen: 81.4, averageWomen: 87.3 },
    { name: 'Australia', averageMen: 81.2, averageWomen: 85.3 },
    { name: 'Switzerland', averageMen: 81.1, averageWomen: 85.2 },
    { name: 'Israel', averageMen: 80.7, averageWomen: 84.8 },
    { name: 'Sweden', averageMen: 80.7, averageWomen: 84.2 },
    { name: 'South Korea', averageMen: 80.5, averageWomen: 86.5 },
    { name: 'Ireland', averageMen: 80.4, averageWomen: 84.1 },
    { name: 'New Zealand', averageMen: 80.3, averageWomen: 83.9 },
    { name: 'Italy', averageMen: 80.1, averageWomen: 84.7 },
    { name: 'Netherlands', averageMen: 79.8, averageWomen: 83.1 },
    { name: 'Cayman Islands', averageMen: 79.8, averageWomen: 84.7 },
    { name: 'Canada', averageMen: 79.7, averageWomen: 83.9 },
    { name: 'Spain', averageMen: 79.7, averageWomen: 85.1 },
    { name: 'Denmark', averageMen: 79.6, averageWomen: 83.6 },
    { name: 'Luxembourg', averageMen: 79.4, averageWomen: 84.2 },
    { name: 'Finland', averageMen: 79.4, averageWomen: 85.0 },
    { name: 'Qatar', averageMen: 79.3, averageWomen: 82.1 },
    { name: 'France', averageMen: 79.2, averageWomen: 85.3 },
    { name: 'Cyprus', averageMen: 79.1, averageWomen: 83.2 },
    { name: 'United Kingdom', averageMen: 79.0, averageWomen: 82.9 },
    { name: 'Austria', averageMen: 78.9, averageWomen: 83.6 },
    { name: 'Belgium', averageMen: 78.6, averageWomen: 83.1 },
    { name: 'Greece', averageMen: 78.6, averageWomen: 83.7 },
    { name: 'Germany', averageMen: 78.6, averageWomen: 83.4 },
    { name: 'Bermuda', averageMen: 78.3, averageWomen: 86.0 },
    { name: 'Portugal', averageMen: 78.0, averageWomen: 84.1 },
    { name: 'Chile', averageMen: 78.0, averageWomen: 82.5 },
    { name: 'Costa Rica', averageMen: 78.0, averageWomen: 83.0 },
    { name: 'Maldives', averageMen: 77.8, averageWomen: 81.0 },
    { name: 'United Arab Emirates', averageMen: 77.4, averageWomen: 79.5 },
    { name: 'Albania', averageMen: 77.2, averageWomen: 80.3 },
    { name: 'Cuba', averageMen: 76.9, averageWomen: 80.9 },
    { name: 'Puerto Rico', averageMen: 76.7, averageWomen: 83.6 },
    { name: 'Bahrain', averageMen: 76.6, averageWomen: 78.6 },
    { name: 'Algeria', averageMen: 75.9, averageWomen: 78.3 },
    { name: 'Iran', averageMen: 75.8, averageWomen: 78.1 },
    { name: 'Morocco', averageMen: 75.6, averageWomen: 78.1 },
    { name: 'Czechia', averageMen: 75.3, averageWomen: 81.3 },
    { name: 'Bosnia and Herzegovina', averageMen: 75.0, averageWomen: 80.0 },
    { name: 'Turkey', averageMen: 75.0, averageWomen: 80.8 },
    { name: 'China', averageMen: 75.0, averageWomen: 79.4 },
    { name: 'Brunei', averageMen: 74.9, averageWomen: 77.3 },
    { name: 'Kuwait', averageMen: 74.9, averageWomen: 76.7 },
    { name: 'Colombia', averageMen: 74.7, averageWomen: 80.2 },
    { name: 'Croatia', averageMen: 74.7, averageWomen: 80.9 },
    { name: 'Ecuador', averageMen: 74.5, averageWomen: 80.0 },
    { name: 'United States', averageMen: 74.5, averageWomen: 80.2 },
    { name: 'Malaysia', averageMen: 74.4, averageWomen: 78.5 },
    { name: 'Peru', averageMen: 74.3, averageWomen: 79.7 },
    { name: 'Estonia', averageMen: 74.2, averageWomen: 82.7 },
    { name: 'Saudi Arabia', averageMen: 74.1, averageWomen: 76.9 },
    { name: 'Sri Lanka', averageMen: 73.8, averageWomen: 80.4 },
    { name: 'Thailand', averageMen: 73.7, averageWomen: 81.1 },
    { name: 'North Macedonia', averageMen: 73.6, averageWomen: 77.9 },
    { name: 'Argentina', averageMen: 73.4, averageWomen: 80.1 },
    { name: 'Montenegro', averageMen: 73.2, averageWomen: 78.8 },
    { name: 'Jamaica', averageMen: 73.0, averageWomen: 76.2 },
    { name: 'Poland', averageMen: 72.6, averageWomen: 80.8 },
    { name: 'Brazil', averageMen: 72.5, averageWomen: 79.7 },
    { name: 'Mexico', averageMen: 72.3, averageWomen: 77.9 },
    { name: 'Hungary', averageMen: 72.3, averageWomen: 79.1 },
    { name: 'Armenia', averageMen: 71.5, averageWomen: 78.7 },
    { name: 'Vietnam', averageMen: 71.4, averageWomen: 79.6 },
    { name: 'Serbia', averageMen: 71.4, averageWomen: 77.2 },
    { name: 'Bangladesh', averageMen: 71.1, averageWomen: 74.9 },
    { name: 'Latvia', averageMen: 70.9, averageWomen: 80.1 },
    { name: 'Mauritius', averageMen: 70.9, averageWomen: 77.7 },
    { name: 'Azerbaijan', averageMen: 70.6, averageWomen: 75.6 },
    { name: 'Romania', averageMen: 70.5, averageWomen: 78.4 },
    { name: 'Lithuania', averageMen: 70.1, averageWomen: 80.0 },
    { name: 'Bulgaria', averageMen: 69.9, averageWomen: 77.5 },
    { name: 'Egypt', averageMen: 69.9, averageWomen: 74.5 },
    { name: 'Indonesia', averageMen: 69.8, averageWomen: 74.2 },
    { name: 'Uzbekistan', averageMen: 69.7, averageWomen: 74.0 },
    { name: 'Nepal', averageMen: 69.5, averageWomen: 72.5 },
    { name: 'Georgia', averageMen: 69.5, averageWomen: 78.3 },
    { name: 'Belarus', averageMen: 69.3, averageWomen: 79.4 },
    { name: 'Bolivia', averageMen: 68.9, averageWomen: 74.8 },
    { name: 'North Korea', averageMen: 68.8, averageWomen: 75.9 },
    { name: 'Iraq', averageMen: 68.7, averageWomen: 72.8 },
    { name: 'El Salvador', averageMen: 68.7, averageWomen: 78.0 },
    { name: 'India', averageMen: 68.7, averageWomen: 71.2 },
    { name: 'Venezuela', averageMen: 68.3, averageWomen: 76.0 },
    { name: 'Cambodia', averageMen: 67.7, averageWomen: 72.2 },
    { name: 'Timor-Leste', averageMen: 67.7, averageWomen: 71.9 },
    { name: 'Philippines', averageMen: 67.4, averageWomen: 75.6 },
    { name: 'Kazakhstan', averageMen: 67.1, averageWomen: 75.5 },
    { name: 'Russia', averageMen: 66.5, averageWomen: 76.4 },
    { name: 'Pakistan', averageMen: 66.5, averageWomen: 68.5 },
    { name: 'Laos', averageMen: 66.4, averageWomen: 70.1 },
    { name: 'Ukraine', averageMen: 66.4, averageWomen: 76.2 },
    { name: 'Senegal', averageMen: 66.0, averageWomen: 70.2 },
    { name: 'Mongolia', averageMen: 66.0, averageWomen: 74.3 },
    { name: 'Fiji', averageMen: 65.8, averageWomen: 69.5 },
    { name: 'Ethiopia', averageMen: 65.0, averageWomen: 68.9 },
    { name: 'Kenya', averageMen: 64.6, averageWomen: 69.4 },
    { name: 'Yemen', averageMen: 64.5, averageWomen: 67.9 },
    { name: 'Burma', averageMen: 64.3, averageWomen: 70.3 },
    { name: 'Tanzania', averageMen: 64.0, averageWomen: 67.6 },
    { name: 'Afghanistan', averageMen: 63.7, averageWomen: 66.7 },
    { name: 'Sudan', averageMen: 63.7, averageWomen: 67.4 },
    { name: 'Ghana', averageMen: 63.2, averageWomen: 65.5 },
    { name: 'Haiti', averageMen: 62.1, averageWomen: 66.5 },
    { name: 'Niger', averageMen: 61.6, averageWomen: 64.0 },
    { name: 'Uganda', averageMen: 61.3, averageWomen: 66.0 },
    { name: 'Zambia', averageMen: 61.1, averageWomen: 67.2 },
    { name: 'Gambia', averageMen: 61.0, averageWomen: 63.8 },
    { name: 'South Africa', averageMen: 61.0, averageWomen: 67.9 },
    { name: 'Zimbabwe', averageMen: 60.0, averageWomen: 63.2 },
    { name: 'Congo (Dem. Republic)', averageMen: 59.4, averageWomen: 62.5 },
    { name: 'Angola', averageMen: 58.7, averageWomen: 64.4 },
    { name: 'Cameroon', averageMen: 58.4, averageWomen: 60.9 },
    { name: 'Equatorial Guinea', averageMen: 58.1, averageWomen: 60.3 },
    { name: 'Ivory Coast', averageMen: 56.9, averageWomen: 59.5 },
    { name: 'Sierra Leone', averageMen: 54.2, averageWomen: 55.9 },
    { name: 'Nigeria', averageMen: 54.1, averageWomen: 56.0 },
    { name: 'Chad', averageMen: 53.1, averageWomen: 55.9 },
    { name: 'Central Africa', averageMen: 51.5, averageWomen: 55.9 }
]